.footer {
  background: #304858;

  .divider {
    background: #4d6d7c;
    opacity: 0.2;
  }

  nav {
    a {
      text-decoration: underline;
    }
  }

  .copyright {
    p {
      font-size: 12px;
      line-height: 18px;
      color: #8099a5;
    }
  }
}
